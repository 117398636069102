export const CONTRACT_TYPES = '/contract-types';
export const FORGOT_PASSWORD = '/auth/forgot-passwor';
export const JOB = '/job';
export const JOB_TYPES = '/job-types';
export const JOB_SHIFT_TYPES = '/job-shift-type';
export const META_FIELDS = '/metainformation/fields'
export const ORGANIZATIONS = '/organisation';
export const PERSON = '/person';
export const POSITION = '/position';
export const RESEND_VERIFICATION = '/auth/resend-verification';
export const RESET_PASSWORD = '/auth/reset-password';
export const SEARCH_SPECIALITY = '/metainformation/job-titles';
export const SEND_FORM = '/metainformation/submit/send-email';
export const SIGNIN = '/auth/login';
export const SIGNUP = '/auth/registration';
export const SUBSCRIPTION = '/subscription';
export const USER = '/user';
