import { all, take, fork } from 'redux-saga/effects';
import * as API from '../../api';
import { execRequest } from '../../services/util';
import * as c from '../constants/agency';


function* getAllJobs() {
    while (true) {
        yield take(c.GET_AGENCY_JOBS_REQUEST);

        yield fork(execRequest, {
            types: [c.GET_AGENCY_JOBS_SUCCESS, c.GET_AGENCY_JOBS_FAIL],
            api: API.jobGet,
        });
    }
}

function* getJobDetails() {
    while (true) {
        const { id } = yield take(c.GET_AGENCY_JOB_DETAILS_REQUEST);

        yield fork(execRequest, {
            types: [c.GET_AGENCY_JOB_DETAILS_SUCCESS, c.GET_AGENCY_JOB_DETAILS_FAIL],
            api: API.jobGetById,
            payload: id,
        });
    }
}

function* watch() {
    yield all([
        fork(getAllJobs),
        fork(getJobDetails),
    ]);
}

export default watch;
