import React from 'react';
import { useTranslation, Trans, getI18n } from 'react-i18next';

import { Main } from '../../layouts';
import { PageTitle, SearchForm, HelmetComponent } from '../../components';
import {
  Search,
  OurPartners,
  Platform,
  Features,
  HowItWorks,
  Feedbacks,
  FindOutMore,
  ContactUs
} from '../../sections';

export const Medlink = ({ i18n, specialities, sendForm }) => {
  const { t } = useTranslation();
  const sectionHead = 'home.landing';

  return (
    <>
      <HelmetComponent sectionHead={sectionHead} />
      <Main>
        <PageTitle sectionHead={sectionHead} id='search_form' />
        <Search sectionHead={sectionHead} specialitiesList={specialities[i18n.language]} sendForm={sendForm} i18n={i18n} />
        <OurPartners sectionHead={sectionHead} />
        <Platform sectionHead={sectionHead} />
        <Features sectionBgColor='#ffffff' sectionHead={sectionHead} />
        <HowItWorks sectionHead={sectionHead} />
        <Feedbacks sectionBgColor='#ffffff' sectionHead={sectionHead} />
        <FindOutMore sectionHead={sectionHead} />
        <ContactUs sectionHead={sectionHead} />
      </Main>
    </>
  )
};
