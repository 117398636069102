import { combineReducers } from 'redux';
import { createLoaderReducers } from './utils';

import * as c from '../constants/common';

const initialState = {
	contractTypes: [],
	jobTypes: [],
	jobShiftTypes: [],
	positions: [],
	metaFields: [],
	error: {},
};

const loaderReducers = createLoaderReducers({
	requestActions: [
		c.GET_CONTRACT_TYPES_REQUEST,
		c.GET_JOB_TYPES_REQUEST,
		c.GET_JOB_SHIFT_TYPES_REQUEST,
		c.GET_META_FIELDS_REQUEST,
		c.GET_POSITIONS_REQUEST,
	],
	successActions: [
		c.GET_CONTRACT_TYPES_SUCCESS,
		c.GET_JOB_TYPES_SUCCESS,
		c.GET_JOB_SHIFT_TYPES_SUCCESS,
		c.GET_META_FIELDS_SUCCESS,
		c.GET_POSITIONS_SUCCESS,
	],
	failActions: [
		c.GET_CONTRACT_TYPES_FAIL,
		c.GET_JOB_TYPES_FAIL,
		c.GET_JOB_SHIFT_TYPES_FAIL,
		c.GET_META_FIELDS_FAIL,
		c.GET_POSITIONS_FAIL,
	],
});

const metaFields = (state = initialState, action) => {
	switch (action.type) {
		case c.GET_META_FIELDS_SUCCESS:
			return action.data;
		case c.GET_META_FIELDS_FAIL:
			return initialState.metaFields;
		default:
			return state;
	}
};

const contractTypes = (state = initialState, action) => {
	switch (action.type) {
		case c.GET_CONTRACT_TYPES_SUCCESS:
			return action.data;
		case c.GET_CONTRACT_TYPES_FAIL:
			return initialState.contractTypes;
		default:
			return state;
	}
};

const jobTypes = (state = initialState, action) => {
	switch (action.type) {
		case c.GET_JOB_TYPES_SUCCESS:
			return action.data;
		case c.GET_JOB_TYPES_FAIL:
			return initialState.jobTypes;
		default:
			return state;
	}
};

const jobShiftTypes = (state = initialState, action) => {
	switch (action.type) {
		case c.GET_JOB_SHIFT_TYPES_SUCCESS:
			return action.data;
		case c.GET_JOB_SHIFT_TYPES_FAIL:
			return initialState.jobShiftTypes;
		default:
			return state;
	}
};
const positions = (state = initialState, action) => {
	switch (action.type) {
		case c.GET_POSITIONS_SUCCESS:
			return action.data;
		case c.GET_POSITIONS_FAIL:
			return initialState.positions;
		default:
			return state;
	}
};

export default combineReducers({
	...loaderReducers,
	metaFields,
	contractTypes,
	jobTypes,
	jobShiftTypes,
	positions,
});
