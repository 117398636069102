/* eslint-disable react/no-multi-comp */
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { NavLink } from "react-router-dom";
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ReactComponent as Help} from '../../../assets/icons/help.svg';
import { USER_ROLES } from '../../../constants/common';
import {
	agency as agencyRoutes,
	hospital as hospitalRoutes,
} from '../../../routes';

export const DRAWER_WIDTH = 232;

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(9, 5, 6),
	},
	logo: {
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: theme.spacing(30),
		'& img': {
			marginLeft: -18,
		}
	},
	mobileDrawer: {
		width: DRAWER_WIDTH,
	},
	desktopDrawer: {
		width: DRAWER_WIDTH,
		height: '100vh'
	},
	icon: {
		minWidth: 44,
	},
	navItem: {
		'& *': {
			color: theme.colors.textLight,
			fontSize: 17,
			fontWeight: 600,
		},
		'& a': {
			color: 'inherit',
		},
	},
	postJobNavItem: {
		backgroundColor: theme.colors.brandPrimary,
		borderRadius: 6,
		marginTop: theme.spacing(10),
		'& *': {
			color: `${theme.palette.background.paper} !important`,
			fontSize: 17,
			fontWeight: 600,
			// fill: `${theme.palette.background.paper} !important`,
		}
	},
	active: {
		// borderLeft: `4px solid ${theme.colors.brandPrimary}`,
		'& *': {
			color: `${theme.colors.brandPrimary} !important`,
			fill: `${theme.colors.brandPrimary} !important`,
		}
	},
}));

function Sidenav({
									openMobile,
									onMobileClose,
									className,
									userInfo,
								}) {
	const classes = useStyles();
	const location = useLocation();
	const { t, i18n } = useTranslation();
	const isHospital = userInfo
		&& userInfo.role
		&& (userInfo.role === USER_ROLES.ORG_ADMIN || userInfo.role === USER_ROLES.ADMIN);
	
	const routes = isHospital
		? hospitalRoutes
		: agencyRoutes;
	
	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		
		// eslint-disable-next-line
	}, [location.pathname]);
	
	const renderRoute = (route) => {
		const isPostJobRoute = isHospital && route.path.includes('/hospital/create-job');
		
		const routeProps = {
			key: route.path,
			component: NavLink,
			to: `/${i18n.language}${route.routerPath || route.path}`,
			exact: true,
		};
		
		if (isPostJobRoute) {
			routeProps.className = clsx(classes.postJobNavItem);
		}
		else {
			routeProps.className = clsx(classes.navItem);
			routeProps.activeClassName = clsx(classes.active);
			routeProps.disableGutters = true;
		}
		//
		return (
			<ListItem {...routeProps}>
				<ListItemIcon className={classes.icon}>
					{route.icon}
				</ListItemIcon>
				<ListItemText>
					
					{t(`menu.${route.name}`)}
				</ListItemText>
			</ListItem>
		);
	}
	
	const content = (
		<div className={clsx(classes.root)}>
			<div className={classes.logo}>
				<div>
					<NavLink to={`/${i18n.language}`}>
						<img
							src="/static/img/logo-hor-rgb.svg"
							alt="Logo"
						/>
					</NavLink>
				</div>
			</div>
			<div className="fill-flex">
				<List
					disablePadding
				>
					{
						routes
							.map((routeInfo) => {
								return (
									<Fragment key={routeInfo.id}>
										{routeInfo.children.map((route) => {
											return renderRoute(route);
										})}
									</Fragment>
								)
							})
					}
				</List>
			</div>
			
			<List
				disablePadding
			>
				<ListItem
					component="a"
					href="mailto:info@medlink.de"
					target="_blank"
					tabIndex={null}
					className={clsx(classes.navItem)}
					disableGutters
				>
					<ListItemIcon className={classes.icon}>
						<Help />
					</ListItemIcon>
					<ListItemText>
						{t(`menu.Need Help`)}
					</ListItemText>
				</ListItem>
			</List>
		</div>
	);
	
	return (
		<>
			<Hidden mdUp>
				<Drawer
					anchor="right"
					classes={{
						paper: clsx(classes.mobileDrawer, className)
					}}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden smDown>
				<Drawer
					anchor="left"
					classes={{
						paper: clsx(classes.desktopDrawer, className)
					}}
					open
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
}

Sidenav.propTypes = {
	className: PropTypes.string,
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
	userInfo: PropTypes.object,
};

export default Sidenav;
