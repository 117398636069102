import * as c from '../constants/auth';

export const authSignIn = (email, password) => ({
	type: c.LOG_IN_REQUEST,
	email,
	password
});

export const authForgotPassword = (email) => ({
	type: c.FORGOT_PASSWORD_REQUEST,
	email,
});

export const authResendVerification = (email) => ({
	type: c.RESEND_VERIFICATION_REQUEST,
	email,
});

export const resetPassword = (email, newPassword, newPasswordToken, currentPassword) => ({
	type: c.RESET_PASSWORD_REQUEST,
	email,
	newPassword,
	newPasswordToken,
	currentPassword,
});

export const authSignUp = ({
														 email,
														 firstName,
														 institution,
														 lastName,
														 phoneNumber,
														 position,
														 password,
														 role,
													 }) => ({
	type: c.SIGN_UP_REQUEST,
	email,
	firstName,
	institution,
	lastName,
	phoneNumber,
	position,
	password,
	role,
});

export const isLoggedIn = () => ({
	type: c.GET_AUTH_USER_DETAILS_REQUEST,
});

export const authLogOut = () => ({
	type: c.LOG_OUT_REQUEST,
});

