const prefix = 'Agency/';

export const GET_AGENCY_JOBS_REQUEST = `${prefix}GET_AGENCY_JOBS_REQUEST`;
export const GET_AGENCY_JOBS_SUCCESS = `${prefix}GET_AGENCY_JOBS_SUCCESS`;
export const GET_AGENCY_JOBS_FAIL = `${prefix}GET_AGENCY_JOBS_FAIL`;

export const GET_AGENCY_JOB_DETAILS_REQUEST = `${prefix}GET_AGENCY_JOB_DETAILS_REQUEST`;
export const GET_AGENCY_JOB_DETAILS_SUCCESS = `${prefix}GET_AGENCY_JOB_DETAILS_SUCCESS`;
export const GET_AGENCY_JOB_DETAILS_FAIL = `${prefix}GET_AGENCY_JOB_DETAILS_FAIL`;

export const CLOSE_JOB_DETAILS = `${prefix}CLOSE_JOB_DETAILS`;
