import React from "react";
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';
import { USER_ROLES, USER_ROLES_URL_PARAMS } from '../../constants/common';
import { authSignUp } from '../../redux/actions/auth';
import isEmpty from 'lodash/isEmpty';
import RegistrationForm from '../../components/auth/Form/Registration';
import Card from '@material-ui/core/Card';


const propTypes = {
  authSignUp: func,
  auth: object,
  local: object
};

const getUserRoleValue = (role) => {
  let roleValue = USER_ROLES.USER;
  
  const validUrlRoleInfo = Object
    .values(USER_ROLES_URL_PARAMS)
    .find((roleInfo) => roleInfo.paramValue === role);
  
  if (validUrlRoleInfo) {
    roleValue = validUrlRoleInfo.value;
  }
  
  return roleValue;
};

function SignUp({ auth, authSignUp }) {
  const {
    role,
  } = useParams();
  
  const handleSendSignUp = (values) => {
    authSignUp({
      ...values,
      role: getUserRoleValue(role),
    });
  }

  const displayErrorMessage = ( msg ) => {
    if(typeof(msg) !== 'object') return msg;

    const err = msg.reduce((acc, item) => {
      const str = Object.values(item.constraints).join(', ');
      acc.push(str);
      return acc;
    }, []);

    const res = err.join(', ');
    return res;
  }
  
  return (
    <>
      <Card className="auth-card">
        <RegistrationForm
          submitError={!isEmpty(auth.error) && displayErrorMessage(auth.error.message)}
          onSubmit={handleSendSignUp}
        />
      </Card>
    </>
  );
}


SignUp.propTypes = propTypes;

const mapStateToProps = (state) =>({
  local : state.local,
  auth : state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  authSignUp: bindActionCreators(authSignUp, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
