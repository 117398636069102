export const EMAIL_PATTERN = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const STRONG_PASSWORD = new RegExp(
    '^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$',
    'g'
  );
export const MEDIUM_PASSWORD = new RegExp(
    '^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$',
    'g'
  );
export const WEAK_PASSWORD = new RegExp('(?=.{8,}).*', 'g');

export const URL_PATTERN = /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9\-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-\/])*)?/;

export const TOKEN_KEY = "redux";

export const USER_ROLES = {
  USER: 'USER',
  ADMIN:'ADMIN',
  ORG_ADMIN: 'ORG_ADMIN',
  ORG_SUB: 'ORG_SUB',
  AGENCY: 'AGENCY'
};

export const USER_ROLES_URL_PARAMS = {
  AGENCY: {
    paramValue: 'agency',
    value: USER_ROLES.AGENCY,
  },
  ORG_ADMIN: {
    paramValue: 'organisation',
    value: USER_ROLES.ORG_ADMIN,
  },
};

export const ACTIVITY_TYPES = [
  {
    _id: 'Tagesdienst',
    name: {
      en: 'Daily Service',
      de: 'Tagesdienst',
    },
  },
  {
    _id: 'Nachtdienst',
    name: {
      en: 'Night Service',
      de: 'Nachtdienst',
    },
  },
  {
    _id: 'Bereitschaftsdienst',
    name: {
      en: 'On Call Service',
      de: 'Bereitschaftsdienst',
    },
  },
];

export const CONTRACT_TYPES = [
  {
    _id: 'Arbeitnehmerueberlassung',
    name: {
      en: 'Temporary employment',
      de: 'Arbeitnehmerueberlassung',
    },
  },
  {
    _id: 'Befristete Anstellung',
    name: {
      en: 'Temporary appointment',
      de: 'Befristete Anstellung',
    },
  },
];

export const JOB_TYPES = [
  {
    _id: 'doctor',
    name: {
      en: 'doctor',
      de: 'doctor [DE]',
    },
  },
  {
    _id: 'nurse',
    name: {
      en: 'nurse',
      de: 'nurse [DE]',
    },
  },
];

export const MOCK_SELECT_VALUES = [
  {
    _id: '123456789 - CURRENTLY NOT AVAILABLE',
    name: {
      en: 'Not available 1',
      de: 'Not available 1 [DE]',
    },
  },
  {
    _id: '987654321 - CURRENTLY NOT AVAILABLE',
    name: {
      en: 'Not available 2',
      de: 'Not available 2 [DE]',
    }
  },
  {
    _id: '987654321 - NOT AVAILABLE IN THE JOB SCHEMA DESIGN',
    name: {
      en: 'Not in schema',
      de: 'Not in schema [DE]',
    }
  },
];


export const SHIFT_TYPES = [
  {
    _id: 'fixed',
    name: {
      en: 'fixed',
      de: 'fixed [DE]',
    },
  },
  {
    _id: 'sporadic',
    name: {
      en: 'sporadic',
      de: 'sporadic [DE]',
    },
  },
];

export const COUNTRY_LIST = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre and Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts and Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks &amp; Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];
