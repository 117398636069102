import { all, take, fork, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as API from '../../api';
import { USER_ROLES } from '../../constants/common';
import { getUserId, parseJwt } from '../../helpers';
import { execRequest } from '../../services/util';
import * as c from '../constants/auth';

function* signUp() {
	while (true) {
		const {
			email,
			firstName,
			institution,
			lastName,
			phoneNumber,
			position,
			password,
			role,
		} = yield take(c.SIGN_UP_REQUEST);
		
		yield fork(execRequest, {
			types: [c.SIGN_UP_SUCCESS, c.SIGN_UP_FAIL],
			api: API.authSignUp,
			payload: {
				email,
				firstName,
				institution,
				lastName,
				phoneNumber,
				position,
				password,
				role,
			},
		});
		
		const data = yield take([c.SIGN_UP_SUCCESS, c.SIGN_UP_FAIL]);
		
		if (data.type === c.SIGN_UP_SUCCESS) {
			const store = yield select();
			const language = store.local.language;
			yield put(push(`/${language}/auth/email-confirmation`));
		}
	}
}


function* signIn() {
	while (true) {
		const {email, password} = yield take(c.LOG_IN_REQUEST);
		
		yield fork(execRequest, {
			types: [c.LOG_IN_SUCCESS, c.LOG_IN_FAIL],
			api: API.authLogIn,
			payload: {email, password},
		});
		
		const data = yield take([c.LOG_IN_SUCCESS, c.LOG_IN_FAIL]);
		if (data.type === c.LOG_IN_SUCCESS) {
			yield put({
				type: c.GET_AUTH_USER_DETAILS_REQUEST,
			});
		}
	}
}

function* forgotPassword() {
	while (true) {
		const {email} = yield take(c.FORGOT_PASSWORD_REQUEST);
		
		yield fork(execRequest, {
			types: [c.FORGOT_PASSWORD_SUCCESS, c.FORGOT_PASSWORD_FAIL],
			api: API.forgotPassword,
			payload: {email},
		});
	}
}

function* resendVerification() {
	while (true) {
		const {email} = yield take(c.RESEND_VERIFICATION_REQUEST);
		
		yield fork(execRequest, {
			types: [c.RESEND_VERIFICATION_SUCCESS, c.RESEND_VERIFICATION_FAIL],
			api: API.resendVerification,
			payload: {email},
		});
	}
}

function* resetPassword() {
	while (true) {
		const {
			email,
			newPassword,
			newPasswordToken,
			currentPassword,
		} = yield take(c.RESET_PASSWORD_REQUEST);
		
		yield fork(execRequest, {
			types: [c.RESET_PASSWORD_REQUEST, c.RESET_PASSWORD_FAIL],
			api: API.resetPassword,
			payload: {
				email,
				newPassword,
				newPasswordToken,
				currentPassword,
			},
		});
	}
}

function* isLoggedIn() {
	yield take(c.GET_AUTH_USER_DETAILS_REQUEST);
	
	yield fork(execRequest, {
		types: [c.GET_AUTH_USER_DETAILS_SUCCESS, c.GET_AUTH_USER_DETAILS_FAIL],
		api: API.getCurrentAccount,
		payload: getUserId(),
	});
	const userInfo = yield take([c.GET_AUTH_USER_DETAILS_SUCCESS, c.GET_AUTH_USER_DETAILS_FAIL]);
	
	const store = yield select();
	const language = store.local.language;
	
	const {
		data: {
			role,
		}
	} = userInfo;
	
	switch (role) {
		case USER_ROLES.ORG_ADMIN:
		case USER_ROLES.ADMIN:
			// yield put(push(`/${language}/hospital`));
			yield put(push(`/${language}/hospital`));
			break;
		case USER_ROLES.AGENCY:
		// case USER_ROLES.ADMIN:
			yield put(push(`/${language}/agency/jobs`));
			break;
		default:
			yield put(push(`/${language}`));
			break;
	}
}

function* logout() {
	yield take(c.LOG_OUT_REQUEST);
	const store = yield select();
	const language = store.local.language;
	
	yield put(push(`/${language}`));
}

function* confirmForgotPassword() {
	yield take(c.FORGOT_PASSWORD_SUCCESS);
	const store = yield select();
	const language = store.local.language;
	
	yield put(push(`/${language}/auth/reset-password-confirmed`));
}

function* confirmResendVerification() {
	yield take(c.RESEND_VERIFICATION_SUCCESS);
	const store = yield select();
	const language = store.local.language;
	
	yield put(push(`/${language}/auth/email-confirmation`));
}


function* confirmResetPassword() {
	yield take(c.RESET_PASSWORD_SUCCESS);
	const store = yield select();
	const language = store.local.language;
	
	yield put(push(`/${language}/auth/password-updated`));
}


function* watch() {
	yield all([
		fork(confirmForgotPassword),
		fork(confirmResetPassword),
		fork(forgotPassword),
		fork(confirmResendVerification),
		fork(resendVerification),
		fork(resetPassword),
		fork(signUp),
		fork(signIn),
		fork(isLoggedIn),
		fork(logout),
	]);
}

export default watch;
